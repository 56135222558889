export class Ajax {
	#parser = null;
	#request = null;
	#active = false;
	#currentUrl = null;
	#listeners = new Set();

	constructor() {
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', this.#init.bind(this));
		} else if (window.Promise) {
			Promise.resolve().then(this.#init.bind(this));
		} else {
			window.setTimeout(this.#init.bind(this), 1);
		}

		if (!window.fetch || !window.DOMParser || !window.URL) {
			return;
		}

		this.#parser = new DOMParser();
		this.#currentUrl = new URL(location);

		document.addEventListener('click', this.#handleClick.bind(this));
		document.addEventListener('keydown', this.#handleKey.bind(this));
		window.addEventListener('popstate', this.#handleHistory.bind(this));
	}

	addListener(listener) {
		this.#listeners.add(listener);
	}

	removeListener(listener) {
		this.#listeners.delete(listener);
	}

	async load(url, transition, pushHistory = true) {
		this.#active = true;

		try {
			const [content] = await Promise.all([
				this.#fetch(url),
				this.#transition(transition, true),
			]);

			pushHistory && history.pushState(null, '', url);
			this.#currentUrl = new URL(url);

			this.#apply(content);
		} catch (e) {
			if (e.name !== 'AbortError') {
				location.href = url;
				return;
			}
		}

		this.#scrollToContent();
		await this.#transition(transition, false);
	}

	#init() {
		for (const listener of this.#listeners) {
			listener();
		}
	}

	async #handleClick(evt) {
		if (evt.defaultPrevented) {
			return;
		}

		const link = evt.target.closest('a');

		if (this.#checkLink(link)) {
			evt.preventDefault();
			await this.load(link.href, link.dataset.transition);
		}
	}

	#handleKey(evt) {
		if (this.#request && evt.keyCode === 27) {
			this.#request.abort();
		}
	}

	async #handleHistory() {
		if (!this.#active || !this.#checkUrl(location)) {
			return;
		}

		await this.load(location.href, null, false);
	}

	#checkLink(link) {
		if (!link) {
			return false;
		}

		if (link.target || link.download || /^(0|false)?$/i.test(link.dataset.ajax ?? 'true')) {
			return false;
		}

		if (!this.#checkUrl(link)) {
			return false;
		}

		return /(\/|\.html?)$/i.test(link.pathname);
	}

	#checkUrl(url) {
		return url.origin === this.#currentUrl.origin && url.href !== this.#currentUrl.href && (
			url.pathname !== this.#currentUrl.pathname
			|| url.search !== this.#currentUrl.search
		);
	}

	async #fetch(url) {
		this.#request = window.AbortController ? new AbortController() : null;

		const response = await fetch(url, {
			signal: this.#request && this.#request.signal,
		});

		try {
			return await response.text();
		} finally {
			this.#request = null;
		}
	}

	#apply(content) {
		const doc = this.#parser.parseFromString(content, 'text/html');

		for (const snippet of doc.querySelectorAll('[id^="snippet-"]')) {
			document.getElementById(snippet.id).innerHTML = snippet.innerHTML;
		}

		document.title = doc.title;
		document.documentElement.setAttribute('lang', doc.documentElement.getAttribute('lang'));

		for (const listener of this.#listeners) {
			listener(doc);
		}
	}

	async #transition(selector, out = false) {
		if (selector === false) {
			return;
		}

		const elms = [...document.querySelectorAll(selector || '.ts-transition-auto')];
		await Promise.all(elms.map(el => this.#transitionElement(el, out)));
	}

	async #transitionElement(element, out = false) {
		const cleanup = [];

		const end = new Promise((resolve) => {
			element.addEventListener('transitionend', resolve);
			cleanup.push(() => element.removeEventListener('transitionend', resolve));
		});

		try {
			await new Promise((resolve, reject) => {
				const tmr = setTimeout(reject, 100);
				element.addEventListener('transitionstart', resolve);
				element.classList.toggle('out', out);

				cleanup.push(() => {
					element.removeEventListener('transitionstart', resolve);
					clearTimeout(tmr);
				});
			});

			await end;
		} catch {
			out && element.classList.toggle('out', false);
		} finally {
			for (const fn of cleanup) {
				fn();
			}
		}
	}

	#scrollToContent() {
		const target = this.#getScrollTarget();

		if (!target) {
			return;
		}

		if (target.scrollIntoView) {
			target.scrollIntoView({ behavior: 'smooth' });
		} else {
			window.scrollTo(0, target.getBoundingClientRect().top + window.scrollY);
		}
	}

	#getScrollTarget() {
		const selector = /^#[a-z][-_.:\w]*$/i.test(this.#currentUrl.hash)
			? this.#currentUrl.hash
			: '.ts-default-scroll-target';
		return document.querySelector(selector);
	}
}
