export class Menu {
	#container = null;
	#threshold = null;
	#fixed = false;
	#menu = false;
	#cleanup = null;

	constructor () {
	}

	init() {
		this.#container = document.getElementById('menu');
		this.#updateThreshold();
		window.addEventListener('scroll', this.#check.bind(this), { passive: false });
		window.addEventListener('resize', this.#updateThreshold.bind(this));
		this.#container.addEventListener('click', this.#handleClick.bind(this));
	}

	#updateThreshold() {
		this.#threshold = this.#container.parentNode.offsetHeight;
		this.#check();
	}

	#check() {
		if (!this.#menu && this.#fixed !== (window.scrollY > this.#threshold)) {
			this.#toggleFixed(!this.#fixed);
		}
	}

	#handleClick(evt) {
		if (!evt.target || !evt.target.closest) {
			return;
		}

		if (evt.target.closest('.nav-menu-toggle')) {
			evt.preventDefault();
			this.#toggleMenuOpen(!this.#menu);
		} else if (evt.target.closest('a')) {
			this.#menu && this.#toggleMenuOpen(false);
		}
	}

	#toggleFixed(fixed) {
		this.#cleanup && this.#cleanup();

		this.#fixed = fixed;
		this.#fixed || (this.#menu = false);

		const end = function() {
			this.#cleanup && this.#cleanup();
			this.#container.classList.remove('nav-fixed-active', 'nav-fixed-out');
			this.#fixed || this.#container.classList.remove('nav-fixed', 'nav-menu-visible');
		}.bind(this);

		this.#cleanup = function() {
			this.#cleanup = null;
			this.#container.removeEventListener('transitionend', end);
		}.bind(this);

		this.#container.addEventListener('transitionend', end);

		if (fixed) {
			this.#container.classList.add('nav-fixed', 'nav-fixed-out');
			sync();
			this.#container.classList.add('nav-fixed-active');
			this.#container.classList.remove('nav-fixed-out');
		} else {
			this.#container.classList.add('nav-fixed-active', 'nav-fixed-out');
		}
	}

	#toggleMenuOpen(open) {
		if (open) {
			this.#fixed || this.#toggleFixed(true);
			sync();
		} else if (this.#fixed && window.scrollY <= this.#threshold) {
			this.#toggleFixed(false);
		}

		this.#container.classList.toggle('nav-menu-visible', this.#menu = open);
	}
}

function sync() {
	return window.scrollY;
}
