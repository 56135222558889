import { Ajax } from '@tinysite/ajax';
import { Menu } from './menu';
import { Gallery } from './gallery';

const ajax = new Ajax();
const menu = new Menu();
const gallery = new Gallery();

ajax.addListener((state) => !state && menu.init());
ajax.addListener(() => gallery.init());
